<template>
    <v-container>
        <base-material-card icon="mdi-factory" title="Emisor de facturación" class="px-5 py-3">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="6">
                            <v-text-field label="Nombre comercial" v-model="emisor.nombre_comercial" :error-messages="errors.nombre_comercial" class="required"></v-text-field>
                            <v-text-field label="RFC" v-model="emisor.rfc" :error-messages="errors.rfc" class="required"></v-text-field>
                            <v-text-field label="Razón social" v-model="emisor.razon_social" :error-messages="errors.razon_social" class="required"></v-text-field>
                            <v-file-input label="Logo (tamaño: 300 x 170px)" v-model="emisor.logo_file" :error-messages="errors.logo_file" accept="image/*" class="required"></v-file-input>
                            <v-file-input label="Archivo .CER" v-model="emisor.archivo_cer_file" :error-messages="errors.archivo_cer_file" accept=".cer" class="required"></v-file-input>
                            <v-file-input label="Archivo .KEY" v-model="emisor.archivo_key_file" :error-messages="errors.archivo_key_file" accept=".key" class="required"></v-file-input>
                            <v-text-field label="Contraseña CSD" v-model="emisor.password_csd" :error-messages="errors.password_csd" class="required"></v-text-field>
                            <v-text-field label="Regimen Fiscal" v-model="emisor.regimen_fiscal" :error-messages="errors.regimen_fiscal" class="required"></v-text-field>
                            <v-text-field label="Lugar de Expedicion" v-model="emisor.lugar_expedicion" :error-messages="errors.lugar_expedicion" class="required"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Calle" v-model="emisor.calle" :error-messages="errors.calle">
                            </v-text-field>
                            <v-text-field label="Núm. Exterior" v-model="emisor.num_exterior" :error-messages="errors.num_exterior"></v-text-field>
                            <v-text-field label="Núm. Interior" v-model="emisor.num_interior" :error-messages="errors.num_interior"></v-text-field>
                            <v-text-field label="Colonia" v-model="emisor.colonia" :error-messages="errors.colonia">
                            </v-text-field>
                            <v-text-field label="Localidad" v-model="emisor.localidad" :error-messages="errors.localidad"></v-text-field>
                            <v-text-field label="Municipio" v-model="emisor.municipio" :error-messages="errors.municipio"></v-text-field>
                            <v-select v-model="emisor.estado_id" :items="estados" label="Estado" item-value="id" item-text="nombre" :error-messages="errors.estado_id"></v-select>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12">
                    <v-data-table :headers="cuentas_headers" :items="emisor.cuentas" item-key="id" hide-default-footer disable-pagination class="elevation-1">
                        <template v-slot:body="{items}">
                            <tr v-for="(item, i) in items">
                                <td>
                                    <v-text-field v-model="item.banco"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field v-model="item.rfc"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field v-model="item.numero"></v-text-field>
                                </td>
                                <td>
                                    <v-btn fab icon x-small @click="selectCuentaDefault(i)">
                                        <v-icon v-if="item.principal == true">fas fa-star</v-icon>
                                        <v-icon v-else>far fa-star</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <v-text-field v-model="cuenta.banco" label="Banco" :error-messages="cuentaErrors.banco" @keyup.enter="addCuenta"></v-text-field>
                                </th>
                                <th>
                                    <v-text-field v-model="cuenta.rfc" label="RFC" :error-messages="cuentaErrors.banco" @keyup.enter="addCuenta"></v-text-field>
                                </th>
                                <th>
                                    <v-text-field v-model="cuenta.numero" type="number" label="Cuenta" :error-messages="cuentaErrors.numero" @keyup.enter="addCuenta"></v-text-field>
                                </th>
                                <th>
                                    <v-btn fab icon x-small @click="addCuenta">
                                        <v-icon>fas fa-plus</v-icon>
                                    </v-btn>
                                </th>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
                <v-col cols="12">
                    <v-btn color="primary" @click="saveEmisor" :loading="loading">Guardar</v-btn>
                    <v-btn @click="$router.push({name: 'Emisor'})" :loading="loading">Cancelar</v-btn>
                    <v-btn v-if="emisor.archivo_cer" @click="downloadFile(emisor.archivo_cer)" :loading="loading">
                        <v-icon left>fas fa-fingerprint</v-icon>
                        Archivo .CER
                    </v-btn>
                    <v-btn v-if="emisor.archivo_key" @click="downloadFile(emisor.archivo_key)" :loading="loading">
                        <v-icon left>fas fa-fingerprint</v-icon>
                        Archivo .KEY
                    </v-btn>
                </v-col>
            </v-row>
        </base-material-card>
    </v-container>
</template>
<script>
    const MODEL = {
        nombre_comercial: null,
        rfc: null,
        razon_social: null,
        archivo_key_file: null,
        archivo_cer_file: null,
        password_csd: null,
        logo: null,
        calle: null,
        num_exterior: null,
        num_interior: null,
        colonia: null,
        localidad: null,
        municipio: null,
        regimen_fiscal: null,
        lugar_expedicion: null,
    }
    const CUENTA = {
        banco: null,
        rfc: null,
        numero: null
    }
    export default {
        data: () => ({
            cuentas_headers: [
                { text: 'Banco', value: 'banco', sortable: false },
                { text: 'RFC', value: 'rfc', sortable: false },
                { text: 'Número', value: 'numero', sortable: false },
                { text: 'Acciones', value: 'accciones', sortable: false },
            ],
            estados: [],
            cuenta: {},
            emisor: {
                cuentas: []
            },
            errors: {},
            cuentaErrors: {},
            loading: false,
        }),
        mounted() {
            this.loadEstados()
            if (this.$route.params.id) {
                this.loadEmisor()
            }
        },
        methods: {
            loadEstados() {
                this.$http.get('/estados/all').then(response => {
                    this.estados = response.data
                })
            },
            loadEmisor() {
                this.$http.get(`/emisores/${this.$route.params.id}`).then(response => {
                    this.emisor = response.data
                })
            },
            saveEmisor() {
                this.errors = {}
                this.loading = true
                if (this.emisor.id) {
                    this.updateEmisor()
                    return
                }
                this.createEmisor()
            },
            createEmisor() {
                this.$http.post('/emisores', this.toFormData(this.emisor)).then(response => {
                    this.emisor.id = response.data.id
                    this.saveCuentas()
                }).catch(error => {
                    console.log(error)
                    switch (error.response.status) {
                        case 422:
                            this.errors = error.response.data.errors
                            break;

                        default:
                            break;
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            updateEmisor() {
                this.$http.post(`/emisores/${this.emisor.id}`, this.toFormData(this.emisor, 'PUT')).then(response => {

                    this.saveCuentas()
                }).catch(error => {
                    switch (error.response.status) {
                        case 422:
                            this.errors = error.response.data.errors
                            break;

                        default:
                            break;
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            addCuenta() {
                if (this.validarCuenta()) {
                    this.emisor.cuentas.push(this.cuenta)
                    this.cuenta = {}
                }
            },
            saveCuentas() {
                if (!this.emisor.cuentas.length) {
                    this.$router.push({ name: 'Emisor' })
                }
                this.emisor.cuentas.forEach((cuenta, i) => {
                    this.$http.post(`/emisores/${this.emisor.id}/cuentas`, cuenta).then(response => {
                        if (this.emisor.cuentas.length - 1 == i) {
                            this.$router.push({ name: 'Emisor' })
                        }
                    })
                })
            },
            selectCuentaDefault(index) {
                this.emisor.cuentas.forEach(cuenta => {
                    cuenta.principal = false
                })
                this.emisor.cuentas[index].principal = true
                console.log(this.emisor.cuentas[index])
            },
            validarCuenta() {
                let isValid = true
                this.cuentaErrors = {}
                if (!this.cuenta.banco) {
                    isValid = false
                    this.cuentaErrors.banco = [
                        "El campo banco es requerido"
                    ]
                }
                if (!this.cuenta.rfc) {
                    isValid = false
                    this.cuentaErrors.rfc = [
                        "El campo rfc es requerido"
                    ]
                }
                if (!this.cuenta.numero) {
                    isValid = false
                    this.cuentaErrors.numero = [
                        "El campo numero es requerido"
                    ]
                }
                return isValid
            },
            downloadFile(url) {
                window.location.assign(url)
            },
            toFormData(object, method) {
                let formData = new FormData

                if (method) {
                    formData.append('_method', method)
                }

                for (const key in object) {
                    if (object.hasOwnProperty(key)) {
                        const element = object[key];
                    }
                    if (object[key] != null) {
                        if (Array.isArray(object[key])) {
                            object[key].forEach(subobject => {
                                formData.append(`${key}[]`, subobject)
                            })
                        } else {
                            formData.append(key, object[key])
                        }
                    }
                }
                return formData
            }
        }
    }
</script>